import React from "react";

export default function CarouselContact() {
  return (
    <div>
      <img
        className=""
        src={require("../../img/CONTACT-WEBSITE-IMG.jpg")}
        alt=""
      />
    </div>
  );
}
